import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hosting7.png";
import macHeroScreenshotImageSrc from "images/woman-office.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";



export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const ActionButton = tw(
    AnchorLink
  )`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
  const PrimaryButton = tw(ActionButton)``;
  const SecondaryButton = tw(
    ActionButton
  )`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;


  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features id="OurServices"
        subheading={<Subheading>Мы предлагаем</Subheading>}
        heading={
          <>
            Мы предлагаем следующие <HighlightedText>Услуги</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Размещение серверов на ведущих хостинг площадках Европы и России</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        
      />
      <FeatureWithSteps id="OurServices"
        subheading={<Subheading>Почему мы</Subheading>}
        heading={
          <>
            Почему нас выбирают <HighlightedText>Наши клиенты?</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Наши принципы</Subheading>}
        heading={
          <>
            Мы всегда придерживаемся наших <HighlightedText>Принципов</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Оптимальные цены",
            description: "Мы обещаем своим клиентам наиболее оптимальные цены",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Профессионализм",
            description: "Наши сотрудники - специалисты высочайшего уровня, имеющие большой опыт работы и решения задач",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      <Pricing
        subheading={<Subheading>Тарифы</Subheading>}
        heading={
          <>
            Гибкие и настраиваемые под потребности <HighlightedText>Тарифные планы</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Основной",
            price: "4000₽",
            duration: "В месяц",
            mainFeature: "Для малых рабочих групп",
            features: ["Удаленное рабочее место", "Антивирусный контроль", "Резервное копирование", "Почтовый сервер", "Облако данных", "Поддержка 24x7", "Облачный офис", "Онлайн чат", "CRM"]
          },
          {
            name: "Расширенный",
            price: "6000₽",
            duration: "В месяц",
            mainFeature: "Базовый тариф, подходящий для большинства компаний",
            features: ["Удаленное рабочее место", "Антивирусный контроль", "Резервное копирование", "Почтовый сервер", "Облако данных", "Поддержка 24x7", "Облачный офис", "Онлайн чат", "CRM", "ERP"],
            featured: true
          },
          {
            name: "Продвинутый",
            price: "7200₽",
            duration: "В месяц",
            mainFeature: "Продвинутый контроль и защищенность",
            features: ["Удаленное рабочее место", "Антивирусный контроль", "Резервное копирование", "Почтовый сервер", "Облако данных", "Поддержка 24x7", "Облачный офис", "Онлайн чат", "CRM", "ERP", "DLP контроль"]
          }
        ]}
      />
      
      <FAQ
        subheading={<Subheading>FAQs</Subheading>}
        heading={
          <>
            У вас есть <HighlightedText>Вопросы ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Что такое - удаленные рабочие места ?",
            answer:
              "Удаленные рабочие места - вы работаете физически не на своем компьютере, а на удаленном сервере, где находится и вся ваша инфраструктура. Фактически ваш компьютер - это облачный компьютер."
          },
          {
            question: "В чем преимущество такого подхода ?",
            answer:
              "Используя наши решения, ваша сетевая инфраструктура становится независима от физического офиса. Все обычные серверные решения находятся в вашем облаке, которое мы обслуживаем. В результате вы можете работать откуда угодно и с какого угодно устройства - в некоторых случаях даже с мобильного телефона - и у вас всегда будет настроенная под вас одна и та же сетевая рабочая станция"
          },
          {
            question: "Какие технологии вы используете ?",
            answer:
              "Для разных задач мы в качестве базы используем технологии или VMWare или Openstack"
          },
          {
            question: "Зачем нам использовать удаленные рабочие столы, если мы можем работать со своего компьютера ?",
            answer:
              "Очень просто. Используя технологию DaaS вы получаете: независимость от компьютера, на котором вы работаете. По факту вам даже не нужно возить с собой компьбтер или ноутбук, чтобы сразу начать работать, найдя подходящее рабочее место. Безопасность - вы можете контролировать работу своих сотрудников и не бояться утечки информации, все данные хранятся на серверах и оперативно резервно копируются"
          },
          {
            question: "Где находятся ваши облачные сервера ?",
            answer:
              "В зависимости от потребностей наших клиентов, мы размещаем наши сервера на хостинге в России, Германии или Финляндии"
          },
          {
            question: "Где вы работаете ?",
            answer:
              "Мы работаем по всему миру. Юридические лица расположены в России, Испании и Великобритании"
          }
        ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
