import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import AboutUsFirstImage from "images/photo-1519389950473-47ba0277781c.jpg";
import AboutUsSecondImage from "images/doctype-hi-res.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>О нас</Subheading>}
        heading="Мы - команда профессионалов в ИТ"
        buttonRounded={false}
        primaryButtonText="Связаться"
        imageSrc = {AboutUsFirstImage}
        textOnLeft={true}
        
      />
      <MainFeature1
        subheading={<Subheading>Наши клиенты</Subheading>}
        heading="Расположены по всему миру"
        description="Великобритания, США, Испания, Франция, Россия, Украина"
        buttonRounded={false}
        primaryButtonText="Связаться"
        imageSrc = {AboutUsSecondImage}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Наши ценности</Subheading>}
        heading="Помогают нам расти"
        description="Вместе с нашими клиентами"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Поддержка 24/7",
            description: "Мы всегда на связи"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Сильная команда",
            description: "Мы - команда профессионалов, имеющая долгий опыт работы и решения различных, в том числе и не стандартных, проблем в ИТ"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Доверие клиентов",
            description: "Клиенты нам доверяют. С некоторыми из них мы работаем уже больше 10 лет"
          },
        ]}
        linkText=""
      />
    
      <Footer />
    </AnimationRevealPage>
  );
};
